//import {test_function} from "./../functions/test_function.js";



import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";
// import { universal_handleErrorFromServer } from "../../universal_methods/universal_handleErrorFromServer.js";
// import { combineSmetioAttributes } from "../../universal_methods/combineSmetioAttributes.js";
import { setTxt } from "../../universal_methods/setTxt.js";


/* 
import { globalVar } from
  "../../../variables/global_variables.js";

import { errorCodes } from
  "../../../constants/errorCodes.js";



import { SmetioOverlayPreloader } from
  "../../smetioOverlayPreloader/SmetioOverlayPreloader.js";

import { SmetioObjectToInput } from
  "../../smetioObjectToInput/v1.0.0/SmetioObjectToInput.js";


import { SmetioAjax } from
  "../../smetioAjax/SmetioAjax.js";
 */



//import "./styles/_main.scss";
import { SmetioSmartbox } from "./../../smetioSmartbox/v1.0.0/SmetioSmartbox";
// import { removeAllWhiteSpace } from "../../../functions/remove/removeAllWhiteSpace.js";
// import { MD5 } from "crypto-js";
import convertBytes from "../../../functions/convert/convertBytes.js";
import axios from "axios";
import { getRandomStringsAndNumbers } from "../../../functions/random/randomStrings.js";






export class SmetioFileUploader {

      constructor(data = {
            signedUrlEndpoint: "",
            subfolder: "logo",

            events: {
                  onFileUploaded: ({ signedUrl, cdnPath }) => {
                        // console.log("**** This is the signedUrl after upload: ", signedUrl)
                        // console.log("**** This is the filePath after upload: ", cdnPath)
                  },
                  onAllFileIsUploaded: ({ files }) => {
                        // // THE FILES OBJECT ie this.files
                        // console.log("###################### ALL upload is done and the files are ", files);
                  },
            },
            validation: {

                  expectations: {
                        /*
        expectedFileType: {
          values: ["image"],// file_extension eg: .svg, .png etc OR FILE TYPE eg: audio/*|video/*|image/*|media_type SEE VALID MEDIA TYPE ON https://www.iana.org/assignments/media-types/media-types.xhtml
          errorText: ""
        },
        expectedExtensions: {
          values: ["svg", "png", "jpg", "jpeg"],
          errorText: ""
        },
        expectedOrientations: {
          values: ["portrait", "landscape", "square"],
          errorText: ""
        },
        expectedDimensions: {
          values: {
            width: 100, // IN px pixels
            height: 100 // IN px pixels
          },
          errorText: ""
        },
        expectedFileSizeInBytes: {
          values: {
            min: 1,
            max: 1048576 // 10 MB
          },
          errorText: ""
        },
        */
                  },

                  minNumberOfFiles: 1,
                  maxNumberOfFiles: 10,

            }
      }) {


            this.getId = getId;
            this.setId = setId;
            this.generateId = generateId;
            this.isEmpty = isEmpty;
            this.getAttributes = getAttributes;
            this.toggleDisplay = toggleDisplay;
            this.setTxt = setTxt;

            this.smetioSmartbox = new SmetioSmartbox();
            this.files = {};
            this.uploadAllFileInProgress = false;
            this.uploadingFile = false;
            this.title = data.label || "txt437";


            this.setId();

            this.validations = data.validation || {};
            this.fileDimension = { // USEFUL FOR CHECKING IF AN IMAGE IS PORTRAIT, LANDSCAPE OR SQUARE
                  width: null,
                  height: null
            };
            this.signedUrlEndpoint = data.signedUrlEndpoint || "";
            this.subfolder = data.subfolder || "";
            this.uploadToStripe = data.uploadToStripe;
            this.preferredNaming = data.preferredNaming || {};
            this.currentPreferredNamingCount = this.preferredNaming.countingStartsAt;


            this.minNumberOfFiles = this.validations.expectations ? this.validations.expectations.minNumberOfFiles : null;
            this.maxNumberOfFiles = this.validations.expectations ? this.validations.expectations.maxNumberOfFiles : null;
            this.expectedExtensions = this.validations.expectations ? this.validations.expectations.expectedExtensions : null;
            this.expectedFileType = this.validations.expectations ? this.validations.expectations.expectedFileType : null;
            this.expectedOrientations = this.validations.expectations ? this.validations.expectations.expectedOrientations : null;
            this.expectedDimensions = this.validations.expectations ? this.validations.expectations.expectedDimensions : null;
            this.expectedFileSizeInBytes = this.validations.expectations ? this.validations.expectations.expectedFileSizeInBytes : null;

            this.onFileUploaded = data.events ? data.events.onFileUploaded : null;
            this.onAllFileIsUploaded = data.events ? data.events.onAllFileIsUploaded : null;

            this.mainClass = "smetio-file-uploader-" + this.id;
            this.mainAttributes = {
                  class: ["smetio-file-uploader", this.mainClass],
            };

            this.dragZoneClass = "smetio-file-uploader-drag-zone-" + this.id;
            this.dragZoneAttributes = {

                  class: ["smetio-file-uploader-drag-zone", this.dragZoneClass],
            };

            this.dragZoneInputClass = "smetio-file-uploader-drag-zone-input-" + this.id;
            this.dragZoneInputAttributes = {
                  type: "file",
                  multiple: this.minNumberOfFiles > 1 ? true : false,
                  accept: this.expectedFileType ? `${this.expectedFileType.values.join(",")}` : "",
                  class: ["smetio-file-uploader-drag-zone-input", this.dragZoneInputClass],
            };

            this.errorTextClass = "smetio-file-uploader-error-text-" + this.id;
            this.errorTextAttributes = {

                  class: ["smetio-file-uploader-error-text", this.errorTextClass],
            };

            this.uploadAllClass = "smetio-file-uploader-upload-all-" + this.id;
            this.uploadAlltAttributes = {

                  class: ["smetio-file-uploader-upload-all", this.uploadAllClass],
            };

            this.listHolderClass = "smetio-file-uploader-file-list-holder-" + this.id;
            this.listHolderAttributes = {

                  class: ["smetio-file-uploader-file-list-holder", this.listHolderClass],
            };


            this.actionsDivClass = "smetio-file-uploader-file-list-action-" + this.id;
            this.actionsDivAttributes = {

                  class: ["smetio-file-uploader-file-list-action", this.actionsDivClass],
            };




      };

      renameFile({ fileHash, newName }) {

            const newFile = new File([this.files[fileHash]], newName, {
                  type: this.files[fileHash].type,
                  lastModified: this.files[fileHash].lastModified,
            });

            this.files[fileHash] = newFile;

            console.log("after renaming the file is ", this.files[fileHash]);

      };

      async handlePreferredNaming({ fileHash }) {

            if (!this.preferredNaming || Object.keys(this.preferredNaming).length < 1) return false;

            console.log("this.preferredNaming ??????????? ", this.preferredNaming);

            console.log(" CALLING HANDLE PREFERRED NAMING WITH ?????????? ", fileHash, " ", this.files[fileHash]);


            const { name, suffix, prefix, separator, addCacheBuster } = this.preferredNaming;
            this.currentPreferredNamingCount = this.currentPreferredNamingCount == null ? "" : this.currentPreferredNamingCount + 1;

            // if (addCacheBuster) {
            //   const getRandomStringsAndNumbers = (await import(/* webpackPrefetch: true */ `../../../functions/random/randomStrings.js`)).getRandomStringsAndNumbers
            //   cacheBuster = `-${getRandomStringsAndNumbers(10)}`;
            // };


            let cacheBuster = addCacheBuster ? "-" + getRandomStringsAndNumbers(10) : "";

            console.log("THE CACHE BUSTER IS ", cacheBuster);

            console.log("this.preferredNaming; ", this.preferredNaming, "addCacheBuster ", addCacheBuster);

            const extension = this.files[fileHash].name.split(".").pop();
            let newName = `${prefix || ""}${name}${separator || ""}${suffix || ""}${this.currentPreferredNamingCount}${cacheBuster}.${extension}`;

            console.log("Thie is the new name ?????????? ", newName);


            this.renameFile({ newName, fileHash });

            newName = "";
      };

      async displayFileDetails(files) {

            // const md5 = await import(/* webpackPrefetch: true */ `crypto-js/md5.js`);

            let output = "";

            for (var i = 0; i < files.length; i++) {

                  // const fileName = files[i].name;
                  // const fileSize = files[i].size;
                  // const fileType = files[i].type;
                  // const lastModifiedDate = files[i].lastModifiedDate;

                  // const fileHash = md5.default(`${fileName}${fileSize}${fileType}${lastModifiedDate}`);
                  const fileHash = getRandomStringsAndNumbers(20);

                  // console.log("**** This is the file hash ", fileHash);
                  // if(this.files[fileHash]) continue;

                  // console.log("The file is ", this.files);

                  this.files[fileHash] = files[i];
                  this.handlePreferredNaming({ fileHash });
                  output += this.getFileList({ fileHash });
            };

            $("." + this.dragZoneClass).attr("data-smetio-file-is-over-drag-zone", false);

            $("." + this.listHolderClass).append(output);

      };

      fileAreDraggedOveDragZone() {
            $("." + this.dragZoneClass).attr("data-smetio-file-is-over-drag-zone", true);
      };

      getOrientation(dimensions = {
            width: null,
            height: null
      }) {

            const { width, height } = dimensions || this.dimensions;

            if (width > height) return "landscape";

            if (width < height) return "portrait";

            return "square";

      };


      isTheRightDimensions(file) {

            if (!this.fileDimension.height || !this.fileDimension.width) return true;

            const { width, height } = this.fileDimension;

            const widthDiff = this.expectedDimensions.values.width - width;
            const heightDiff = this.expectedDimensions.values.height - height;

            if (widthDiff > 10 || widthDiff < -10) return false;
            if (heightDiff > 10 || heightDiff < -10) return false;

            return true;
      };

      validateFiles(files) {

            console.log("This is the file in the validate files ", files);
            this.hideError();
            if (this.minNumberOfFiles && files.length < this.minNumberOfFiles) return this.showError("txt441");

            if (this.maxNumberOfFiles && files.length > this.maxNumberOfFiles) return this.showError("txt440");
            if (this.maxNumberOfFiles && (Object.keys(this.files).length + 1) > this.maxNumberOfFiles) return this.showError("txt440");

            if (!this.validations) return true;
            if (!this.validations.expectations) return true;
            if (Object.keys(this.validations.expectations).length < 1) return true;

            for (let i = 0; i < files.length; i++) {

                  const file = files[i];

                  console.log("this is the file in iteration ", file);

                  const type = file.type;
                  const fileSize = file.size;
                  const extension = file.name.split(".").pop();

                  // console.log("Test 1 is passed ");

                  if (this.expectedFileType) {

                        const fileTypeText = type.split("/")[0];

                        const typeIsAsExpected = this.expectedFileType.values.map(item => item.split("/")[0] == fileTypeText).some(Boolean);

                        if (!typeIsAsExpected) return this.showError(this.expectedFileType.errorText || "txt442");
                  };

                  // console.log("Test 2 is passed ");

                  if (this.expectedExtensions) {
                        // console.log("the extension ", extension);
                        if (!this.expectedExtensions.values.includes(extension)) return this.showError(this.expectedExtensions.errorText || "txt443");
                  };

                  // console.log("Test 3 is passed ");

                  if (this.expectedOrientations) {
                        if (!this.expectedOrientations.values.includes(this.getOrientation())) return this.showError(this.expectedOrientations.errorText || "txt444");
                  };

                  // console.log("Test 4 is passed ");

                  if (this.expectedFileSizeInBytes) {
                        if (fileSize < this.expectedFileSizeInBytes.values.min) return this.showError(this.expectedFileSizeInBytes.errorText || "txt445");
                        if (fileSize > this.expectedFileSizeInBytes.values.max) return this.showError(this.expectedFileSizeInBytes.errorText || "txt446");
                  };

                  // console.log("Test 5 is passed ");


                  if (this.expectedDimensions) {
                        if (!this.isTheRightDimensions(file)) return this.showError(this.expectedDimensions.errorText || "txt447");
                  };

                  // console.log("Test 6 is passed ");

            };

            return this.hideError();

      };

      fileAreDroppedDragZone(event, fileObj = {}) {

            // this.hideError();
            const files = event ? event.originalEvent.dataTransfer.files : [fileObj];

            console.log("This is the file in the drop zone ", files);

            const validationIsPassed = this.validateFiles(files);



            if (validationIsPassed !== true) return false;

            return this.displayFileDetails(files);

      };

      getDragZone() {

            const dropText = this.setTxt({
                  attributes: {},
                  txt: "txt435",
                  type: "html"
            });

            const browseText = this.setTxt({
                  attributes: {},
                  txt: "txt436",
                  type: "html"
            });

            const uploadAllText = this.setTxt({
                  attributes: this.uploadAlltAttributes,
                  txt: "txt438",
                  type: "html"
            });

            return `

      <div class="smetio-file-uploader-drag-zone-holder">

        <div ${this.getAttributes(this.dragZoneAttributes, false)}">

          <div class="smetio-file-uploader-drag-zone-icon">
            <i class="smetio-icon smetio-icon-upload2"></i>
          </div>
          <div class="smetio-file-uploader-drag-zone-txt">
            ${dropText}
          </div>

          <button class="smetio-file-uploader-drag-zone-btn">
            ${browseText}
          </button>

          <input ${this.getAttributes(this.dragZoneInputAttributes, false)}>

        </div>

        <div ${this.getAttributes(this.errorTextAttributes, false)}  > Too many files </div>

        <div ${this.getAttributes(this.uploadAlltAttributes, false)}  > 
          <i class="smetio-icon smetio-icon-upload1"></i>
          <span>${uploadAllText} </span>
        </div>


      </div>
    `;
      };

      showError(errorText) {

            errorText = this.setTxt({
                  element: {},
                  txt: errorText,
                  type: "html"
            });

            // console.log("This is the error to be shown ", errorText);
            $("." + this.errorTextClass).html(errorText).fadeIn();

            return false;
      };

      hideError() {
            $("." + this.errorTextClass).html("").fadeOut();
            return true;
      };

      revokeObjectURL(imgSrc) {
            URL.revokeObjectURL(imgSrc);
      };

      getThumbnailImage(file) {

            // const imgSrc = URL.createObjectURL(file);
            const imgId = generateId();

            const reader = new FileReader();
            reader.onload = (e) => {

                  const imgForComputingDimension = new Image();

                  imgForComputingDimension.onload = () => {
                        this.fileDimension = {
                              width: imgForComputingDimension.width,
                              height: imgForComputingDimension.height
                        };

                  };

                  $("#" + imgId).attr("src", e.target.result);
            };
            reader.readAsDataURL(file);

            return `
      <img id="${imgId}" src="" >
    `;

            /*     const img = $("<img/>");
        img.attr("src",imgSrc);
        img.attr("onload", ()=>{
          URL.revokeObjectURL(imgSrc);
        });

        return img; 
        <img id="${imgId}" src="${imgSrc}" onload="${this.revokeObjectURL(imgSrc)}" >
    */

      };

      getFileList({ file, fileHash }) {

            file = file || this.files[fileHash];
            const fileName = file.name.slice(0, 30);
            const fileSize = file.size;
            let thumbnailImg = "";
            if (file.type.startsWith("image/")) thumbnailImg = this.getThumbnailImage(file);

            return `
      <div data-smetio-file-hash="${fileHash}" class="smetio-file-uploader-file-list smetio-file-uploader-file-list-${fileHash}">

        <div class="smetio-file-uploader-file-list-thumbnail">${thumbnailImg}</div>

        <div class="smetio-file-uploader-file-list-name-and-more">

          <div class="smetio-file-uploader-file-list-name-size-actions">

            <div class="smetio-file-uploader-file-list-name" title=" ${file.name}"> ${fileName} </div>

            <div class="smetio-file-uploader-file-list-size"> ${convertBytes(file.size)} </div>

            <div ${this.getAttributes(this.actionsDivAttributes, false)}  >

              <div data-smetio-file-hash="${fileHash}" data-smetio-action="remove" class="smetio-file-uploader-file-list-action-remove smetio-file-uploader-file-list-action-remove-${fileHash}">
                <i class="smetio-icon smetio-icon-trash-o"></i>
              </div>

              <div data-smetio-file-hash="${fileHash}" data-smetio-action="cancel"  class="smetio-file-uploader-file-list-action-cancel smetio-file-uploader-file-list-action-cancel-${fileHash}">
                <i class="smetio-icon smetio-icon-cancel-circle"></i>
              </div>

              <div data-smetio-file-hash="${fileHash}" data-smetio-action="upload"  class="smetio-file-uploader-file-list-action-upload smetio-file-uploader-file-list-action-upload-${fileHash}">
                <i class="smetio-icon smetio-icon-upload21"></i>
              </div>

              <div data-smetio-file-hash="${fileHash}" class="smetio-file-uploader-file-list-action-done smetio-file-uploader-file-list-action-done-${fileHash}">
                <i class="smetio-icon smetio-icon-check-square1"></i>
              </div>              

            </div>
          </div>

          <div class="smetio-file-uploader-file-list-progress smetio-file-uploader-file-list-progress-${fileHash}"></div>

        </div>

      </div>
    `;
      }

      getFileListHolder() {

            return `
      <div ${this.getAttributes(this.listHolderAttributes, false)}>
      </div>
    `;
      }

      close() {
            this.files = {};
            this.smetioSmartbox.close();
      };

      reactivate() {

            if (!this.smetioSmartbox) this.smetioSmartbox = new SmetioSmartbox();

            if (this.smetioSmartbox.hasBeenRendered && this.smetioSmartbox.currentState !== "close") {
                  return this.smetioSmartbox.reactivate();
            };

            return this.getSmartBoxAndContent();
      };

      async getSmartBoxAndContent() {

            const SmetioPreloader = (await import(/* webpackPrefetch: true */ "../../smetioPreloader/SmetioPreloader.js")).SmetioPreloader;

            if (!this.preloader) this.preloader = new SmetioPreloader();
            const preloader = this.preloader.render();

            const content = `
    
    <div ${this.getAttributes(this.mainAttributes, false)}>

      ${preloader}

      ${this.getDragZone()}
      ${this.getFileListHolder()}

    </div>
  `;

            this.smetioSmartbox.title = this.title;
            this.smetioSmartbox.content = content;
            this.smetioSmartbox.onClose = () => {
                  if (this.files) this.files = {};
                  this.smetioSmartbox = null;
                  if (this.onAllFileIsUploaded) this.onAllFileIsUploaded({ files: this.files });
            };

            return this.smetioSmartbox.render();

      };

      render() {

            // if (this.smetioSmartbox.hasBeenRendered && this.smetioSmartbox.currentState !== "close") {
            //   return this.smetioSmartbox.reactivate();
            // };


            this.events();
            return this.reactivate();

            // return this.getSmartBoxAndContent();
            // this.smetioSmartbox.title = this.title;
            // this.smetioSmartbox.content = content;
            // this.smetioSmartbox.onClose = () => {
            //   if (this.files) delete this.files;
            //   this.smetioSmartbox = null;
            //   if (this.onAllFileIsUploaded) this.onAllFileIsUploaded({ files: this.files });
            // };
            // return this.smetioSmartbox.render();

      };

      removeFileFromList(fileHash) {

            if (this.files[fileHash]) delete this.files[fileHash];
            $(`.smetio-file-uploader-file-list-${fileHash}`).remove();

      };

      async uploadAllFiles() {

            if (this.uploadAllFileInProgress) return false;

            this.setTxt({
                  element: `.${this.uploadAllClass}`,
                  txt: "txt439",
                  type: "html"
            });

            this.uploadAllFileInProgress = true;

            for (const fileHash in this.files) {

                  // const file = this.files[fileHash];

                  await this.uploadFile({ fileHash });

            };

            this.uploadAllFileInProgress = false;
            this.setTxt({
                  element: `.${this.uploadAllClass}`,
                  txt: "txt438",
                  type: "html"
            });

            if (this.onAllFileIsUploaded) this.onAllFileIsUploaded({ files: this.files });

      };

      async getSignedUrl(fileHash) {

            // const url = `${this.signedUrlEndpoint}/signedUrl/upload/${window.smetioVisitor.language}`;

            this.preloader.show();

            const file = this.files[fileHash];

            // this.signedUrlEndpoint = this.signedUrlEndpoint || `http://localhost:8080/api/signedUrl/upload/img/${window.smetioVisitor.language}`;
            this.signedUrlEndpoint = this.signedUrlEndpoint || `${window.apiEndPoint}/signedUrl/upload/img/${window.userLanguage}`;

            await axios.get(this.signedUrlEndpoint, {
                  params: {
                        fileType: file.type,
                        fileName: file.name,
                        subfolder: this.subfolder
                  },
            }).then((response) => {

                  // console.log("this is the response: ", response);
                  const { signedUrl, filePath } = response.data;

                  return this.uploadFile({ fileHash, signedUrl, filePath });

            }).catch((error) => {
                  // handle error
                  console.log(error);
                  // return false;
            }).finally(() => {
                  // always executed
                  this.preloader.hide();
            });

      };

      async compressImage({ fileHash }) {

            const file = this.files[fileHash];

            const compressor = await import(/* webpackPrefetch: true */ "compressorjs");

            // console.log("this is the compressor: ", compressor);

            const Compressor = compressor.default;

            // import Compressor from 'compressorjs';
            return new Compressor(file, {
                  quality: 0.6,
                  success: (result) => {
                        this.files[fileHash] = result;
                        this.files[fileHash]["isCompressed"] = true;
                        this.uploadFile({ fileHash });
                  },
                  error(err) {
                        console.log(err.message);
                  },
            });

      };

      async compressPdf({ fileHash }) {

            const file = this.files[fileHash];
            const PDFDocument = (await import(/* webpackPrefetch: true */ "pdf-lib")).PDFDocument;
            const reader = new FileReader();

            reader.onload = async () => {
                  const pdfData = new Uint8Array(reader.result);

                  try {

                        console.log("About to Loaded pdf file");
                        const pdfDoc = await PDFDocument.load(pdfData);

                        console.log("Loaded pdf file");
                        // Apply additional compression techniques here if needed
                        /* 
        // Compress images by reducing resolution
        const pages = pdfDoc.getPages();
        for (let i = 0; i < pages.length; i++) {
          const page = pages[i];

          console.log("the page: ", page);
     

          // const images = page.getImages();
          const images = page.doc.images;
          console.log("the images: ", images);

          for (const image of images) {
            const { width, height } = image.size();
            // Reduce image resolution by half
            const resizedImage = await page.embedPng(image, { width: width / 2, height: height / 2 });
            image.reset(resizedImage);
          };
        };

 */
                        // Create a new PDF document
                        const newPdfDoc = await PDFDocument.create();
                        console.log("Created pdf new file");

                        const pageCount = pdfDoc.getPageCount();
                        console.log("Page count: ", pageCount);

                        for (let i = 0; i < pageCount; i++) {
                              const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
                              newPdfDoc.addPage(copiedPage);
                        };

                        console.log("About to save the new pdf file");
                        // Serialize the new PDF
                        const compressedPdfBytes = await newPdfDoc.save();

                        // console.log("Saved the new pdf file")
                        const newFile = new File([compressedPdfBytes], this.files[fileHash].name, {
                              type: this.files[fileHash].type,
                              lastModified: this.files[fileHash].lastModified,
                        });

                        // console.log("Added compressed pdf file to the list");

                        this.files[fileHash] = newFile;
                        this.files[fileHash]["isCompressed"] = true;
                        return this.uploadFile({ fileHash });

                  } catch (error) {
                        console.error("Error compressing PDF:", error);
                  }
            };

            return reader.readAsArrayBuffer(file);


            // const PDFDocument = (await import(/* webpackPrefetch: true */ `pdf-lib`)).PDFDocument;

            // console.log("About to Loaded pdf file");
            // // Load the PDF
            // const pdfDoc = await PDFDocument.load(file);
            // console.log("Loaded pdf file");

            // // Create a new PDF document
            // const newPdfDoc = await PDFDocument.create();
            // console.log("Created pdf new file")

            // // Copy pages from the original PDF to the new PDF
            // const pageCount = pdfDoc.getPageCount();
            // console.log("Page count: ", pageCount);

            // for (let i = 0; i < pageCount; i++) {
            //     const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
            //     newPdfDoc.addPage(copiedPage);
            // };

            // console.log("About to save the new pdf file")
            // // Serialize the new PDF
            // const compressedPdfBytes = await newPdfDoc.save();

            // console.log("Saved the new pdf file")
            // const newFile = new File( [compressedPdfBytes], this.files[fileHash].name, {
            //   type: this.files[fileHash].type,
            //   lastModified: this.files[fileHash].lastModified,
            // });

            // console.log("Added compressed pdf file to the list");

            // this.files[fileHash] = newFile;
            // this.files[fileHash]["isCompressed"] = true;

            // return this.uploadFile({ fileHash });

            // return compressedPdfBytes;

      };


      checkIfAllSelectedFilesAreUploaded() {
            for (const fileHash in this.files) {
                  if (!this.files[fileHash]["uploaded"]) return false;
            };
            // return true;
            if (this.onAllFileIsUploaded) this.onAllFileIsUploaded({ files: this.files });


      };

      async uploadFileToStripeServer(data = {}) {

            const { fileHash } = data;

            const cdnPath = this.files[fileHash]["cdnPath"];

            this.preloader.show();
            this.preloader.addExtraText("txt594");

            const url = `${window.apiEndPoint}/stripeRoute/uploadFile/${window.smetioVisitor.language}`;

            const fileUploadedToStripe = await axios.post(url, { cdnPath, ...this.uploadToStripe }).then((response) => {

                  const fileId = response.data;

                  if (fileId.error) return window.smetioVisitor.showNotification({ type: "success", body: [{ message: fileId.msg }] });

                  console.log("This is the fileId from stripe ", fileId);

                  this.files[fileHash]["stripeFileId"] = fileId.msg;

                  return true;

            }).catch((error) => {
                  // handle error
                  console.log(error);
                  // return false;
            }).finally(() => {
                  // always executed
                  this.preloader.hide();
            });

            return fileUploadedToStripe;

      };

      async uploadFile({ fileHash, signedUrl, filePath }) {
            // const {fileHash, signedUrl } = data;



            if (this.files[fileHash]["uploaded"]) return false;

            if (!signedUrl) return this.getSignedUrl(fileHash);

            // if (!signedUrl) return false;

            if (!this.files[fileHash] || this.uploadingFile) return false;
            const doNotCompress = ["svg", "gif"];


            const extension = this.files[fileHash].name.split(".").pop();
            if (this.files[fileHash].type.startsWith("image/") && !this.files[fileHash]["isCompressed"] && doNotCompress.indexOf(extension) === -1) return this.compressImage({ fileHash });
            if (this.files[fileHash].type == "application/pdf" && !this.files[fileHash]["isCompressed"] && doNotCompress.indexOf(extension) === -1) return this.compressPdf({ fileHash });


            $(`.smetio-file-uploader-file-list-action-upload-${fileHash}`).fadeOut();
            $(`.smetio-file-uploader-file-list-action-cancel-${fileHash}`).fadeIn();
            $(`.smetio-file-uploader-file-list-action-remove-${fileHash}`).fadeOut();



            // console.log("This is the content type ", mime.contentType(this.files[fileHash].name));

            const mime = await import(/* webpackPrefetch: true */ "mime");

            // console.log("mime is ", mime);
            // console.log("mime type is ", mime.default.getType(this.files[fileHash].name));

            // const formData = new FormData();
            // formData.append('file', this.files[fileHash]);


            const config = {
                  onUploadProgress: (progressEvent) => {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log(percentCompleted);
                        $(`.smetio-file-uploader-file-list-progress-${fileHash}`).css("width", percentCompleted + "%");

                        if (percentCompleted >= 100) {

                              $(`.smetio-file-uploader-file-list-action-upload-${fileHash}`).fadeOut();
                              $(`.smetio-file-uploader-file-list-action-cancel-${fileHash}`).fadeOut();
                              $(`.smetio-file-uploader-file-list-action-remove-${fileHash}`).fadeOut();
                              $(`.smetio-file-uploader-file-list-action-done-${fileHash}`).fadeIn();

                              // if(this.files[fileHash]) delete this.files[fileHash];
                              if (this.files[fileHash]) this.files[fileHash]["uploaded"] = true;
                              this.uploadingFile = false;

                        };

                  },
                  headers: {
                        // 'Access-Control-Allow-Origin' : signedUrl,
                        // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                        // 'Content-Type': "multipart/form-data",
                        "Content-Type": mime.default.getType(this.files[fileHash].name),
                        // 'Access-Control-Allow-Headers': ',
                        // 'x-amz-acl': 'public-read',
                        // 'contentType':  this.files[fileHash].type,
                        contentType: mime.default.getType(this.files[fileHash].name),
                        // 'Access-Control-Request-Headers ': 'Content-Type',
                        // 'Access-Control-Request-Method': 'PUT'
                  },
                  // withCredentials: true,
            };


            this.uploadingFile = true;
            let data = new FormData();
            data.set(this.files[fileHash].name, this.files[fileHash], { type: this.files[fileHash].type });

            // console.log("The data is ", data);

            // console.log("This is the type of the file: ", this.files[fileHash].type);

            // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

            // await axios.put(signedUrl, data, config).then((res) => {

            this.preloader.show();
            await axios.put(signedUrl, this.files[fileHash], config).then(async (res) => {

                  // console.log(res);
                  this.files[fileHash]["uploaded"] = true;
                  this.files[fileHash]["cdnPath"] = `https://cdn.3ack.com/${filePath}`;

                  if (this.uploadToStripe) await this.uploadFileToStripeServer({ fileHash });
                  // this.uploadingFile = false;
                  if (this.onFileUploaded) this.onFileUploaded({ signedUrl, cdnPath: this.files[fileHash]["cdnPath"] });
                  // return res;
                  return this.checkIfAllSelectedFilesAreUploaded();

            }).catch((error) => {

                  console.log(error);

                  return error;

            }).finally(() => {
                  this.preloader.hide();
            });


      };

      performAction(data = {}) {

            const { fileHash, action } = data;

            switch (action) {
            case "remove": return this.removeFileFromList(fileHash);

                  break;

            case "upload": return this.uploadFile({ fileHash });

                  break;

            default: return false;
                  break;
            }

      };

      events() {

            $("body").on("drop dragdrop", "." + this.dragZoneClass, (event) => {

                  event.preventDefault();
                  this.fileAreDroppedDragZone(event);

                  // event.preventDefault();
                  // // console.log("You dragged drop dragdrop me!");
                  // alert("You dragged drop dragdrop me!");


            });

            $("body").on("dragenter dragover", "." + this.dragZoneClass, (event) => {

                  event.preventDefault();
                  this.fileAreDraggedOveDragZone();

            });

            $("body").on("change", "." + this.dragZoneInputClass, (event) => {

                  // event.preventDefault();
                  const files = $("." + this.dragZoneInputClass)[0].files;

                  console.log("This is the file list: ", files);

                  this.fileAreDroppedDragZone(null, files[0]);
                  // this.fileAreDroppedDragZone(event);

                  // this.displayFileDetails(files);

            });

            $("body").on("mousedown", "." + this.dragZoneClass + " button", (event) => {

                  event.preventDefault();
                  this.hideError();
                  // alert("You just clickedme!");

                  $("." + this.dragZoneInputClass).trigger("click");

            });

            $("body").on("mousedown", "." + this.actionsDivClass + " div", (e) => {

                  const fileHash = $(e.currentTarget).attr("data-smetio-file-hash");
                  const action = $(e.currentTarget).attr("data-smetio-action");

                  this.performAction({ fileHash, action });

            });

            $("body").on("mousedown", "." + this.uploadAllClass, (e) => {

                  this.uploadAllFiles();

            });





      };

};

// const smetioFileUploader = new SmetioFileUploader();
// smetioFileUploader.render();

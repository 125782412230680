export function init() {

    console.log("The init function is called ");
    
    this.waitingForVisitorToRegister = setInterval(() => {

        if (!window.smetioVisitor) return true;

        if (!window.smetioVisitor.isRegistered) return true;

        // if (!window.smetioVisitor.isLoggedIn) return location.href = SMETIO_LINK_LOGIN_PAGE;

        clearInterval(this.waitingForVisitorToRegister);

        return this.render();

    }, 500);

};

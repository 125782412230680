
/* 
    IMPORT JQUERY STUFFS .... JQUERY IS LOADED AS A PLUGIN ON webpack.config.js

    FOR JQUERY UI DRAGGABLE AND RESIZABLE --- OTHER WIDGET/FUNCTIONS MUST BE ADDED IF 
    THEY ARE TO BE USED
*/
    //const jQuery = window.$ = window.jQuery = require("jquery");

    require("webpack-jquery-ui/draggable");
    require("webpack-jquery-ui/resizable");
    require("webpack-jquery-ui/datepicker");
    //require("webpack-jquery-ui/sortable"); IMPORT THEM INTO THE FILE THAT NEEDS THEM
    //require("webpack-jquery-ui/selectmenu");
    //require("webpack-jquery-ui/accordion");

    //require("webpack-jquery-ui/widgets.js");
    //require("jquery-ui/ui/widgets/mouse.js");

    //require("jquery.touch");
    
    // FOR MAKING DRAG AND RESIZE POSSIBLE ON MOBILE
    require('jquery-ui-touch-punch');

    //import "jquery-ui-touch-punch/jquery.ui.touch-punch.min.js";


/* 
    FOR ADDING ICONS -- ONLY font-awesome ICONS ARE ADDED BUT GOOGLE MATERIAL ICON 
    AND GLYPHICONS CAN ALSO BE ADDED 
    
    ALL CAN BE ADDED BY SIMPLY ADDING require("webpack-icons-installer");
*/

    // require("webpack-icons-installer/font-awesome");

    //import '@fortawesome/fontawesome-free/js/all';
    //import '@fortawesome/fontawesome-free/css/all.css';


/* 
    FOR PARTICLE ANIMATION 
*/
    // require("particles.js");
    
    // import {particles} from 'particles.js';

/*
    IMPORT CSS FILES -- ALL CSS FILES/FRAMEWORK/MODULES MUST BE IMPORTED IN 
    THE css/styles.scss FILE AND THEN THE css/styles.scss FILE MUST BE IMPORTED HERE

*/    
    
    import "./css/styles.scss";


/*

    ALL JAVASCRIPT SCRIPTS/FUNCTIONS/VARIABLES/MODULES/CONSTANTS/CLASSES THAT NEEDED 
    ARE TO BE IMPORTED BELOW 
    
    eg: import "./js/scripts/mytestScript.js";
    
*/


// import "popper.js";
//popper.js/dist/popper.js


// import "bootstrap";

//import {Carousel} from "bootstrap/js/dist/carousel";


/**** IMPORT SMETIO SPECIFIC JS FILES ****/




// import "./js/apps_and_websites/colors/colors.js";

// import "./js/apps_and_websites/smetio_website/smetio_signup_or_login_page.js";

// import "./js/apps_and_websites/smetio_icons/smetio_icons.js";

// import "./js/apps_and_websites/smetio_website/smetio_multimediaPlayer.js";
// import { debounce } from './js/classes/universal_methods/debounce';





/**** ALL SMETIO APPS ARE IMPORTED INSIDE THE FILE BELOW ****/

export { SmetioProfile } from './js/classes/smetioApps/smetioProfile/v1.0.0/SmetioProfile.js';
export { checkIfItIsEmpty } from './js/functions/checks/checkIfItIsEmpty.js';
export { SmetioVisitor } from './js/classes/smetioVisitor/SmetioVisitor.js';

export { SmetioSlideShow } from "./js/classes/smetioSlideShow/SmetioSlideShow.js";
export { SmetioIcons } from "./js/classes/smetioIcons/v1.0.0/SmetioIcons.js";

export {SmetioColorSchemeMaker} from "./js/classes/smetioColorSchemeMaker/v1.0.0/SmetioColorSchemeMaker.js";
export {SmetioDataPresentation} from "./js/classes/smetioDataPresentation/v1.0.0/SmetioDataPresentation.js";
export {SmetioDataGroup} from "./js/classes/smetioDataGroup/v1.0.0/SmetioDataGroup.js";
export {debounce} from "throttle-debounce";
export {Admin3ack} from './js/classes/3ack_apps/admin3ack/v1.0.0/Admin3ack';
export {Employee3ack} from './js/classes/3ack_apps/employee3ack/v1.0.0/Employee3ack';
export { SmetioFileUploader } from './js/classes/smetioFileUploader/v1.0.0/SmetioFileUploader';
export {SmetioCreditCardDetailsCollector} from './js/classes/smetioCreditCardDetailsCollector/v1.0.0/SmetioCreditCardDetailsCollector';
export {SmetioLoginPage} from "./js/classes/smetioLoginPage/v1.0.0/SmetioLoginPage";
export { SmetioCodeVerificationPage } from "./js/classes/smetioCodeVerificationPage/v1.0.0/SmetioCodeVerificationPage";
export {SmetioSignupPage} from "./js/classes/smetioSignupPage/v1.0.0/SmetioSignupPage";
export {SmetioLogoutPage} from "./js/classes/smetioLogoutPage/v1.0.0/SmetioLogoutPage";
export {SmetioResetPasswordPage} from "./js/classes/smetioResetPasswordPage/v1.0.0/SmetioResetPasswordPage";
export {SmetioCongratulationPage} from "./js/classes/smetioCongratulationPage/v1.0.0/SmetioCongratulationPage";
export {SmetioNotificationPage} from "./js/classes/smetioNotificationPage/v1.0.0/SmetioNotificationPage";
export {SmetioLegalPoliciesPage} from "./js/classes/smetioLegalPoliciesPage/v1.0.0/SmetioLegalPoliciesPage";
export { SmetioCustomerWebsiteController } from "./js/classes/smetioCustomerWebsiteController.js/v1.0.0/SmetioCustomerWebsiteController.js";




// export {sayHello} from "smetio-simple-functions";
// export { isValidNumber} from "@schaniag/private";
// export { getHtmlInputTypeByType } from "@schaniag/private";






// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 



import { SmetioPage } from "../../smetioPage/v1.0.0/SmetioPage";


//import "./styles/_main.scss";


export class SmetioNotificationPage extends SmetioPage{

  constructor(data = {}) {

    super(data);

    this.modelNotNeeded = true;
    this.displayMessageAfterRender = true;
    this.mainClass = "smetio-congratulation-page" + this.id;
    this.mainAttributes = {
      class: ["smetio-congratulation-page", this.mainClass],
    };

    // setTimeout(() => {

    //   this.displayCongratulationMessage();
      
    // }, this.defaultTimeout);

  };

  async displayMessage(){

    console.log("I am now displaying message");

    const getCongratulationHeading = (await import(/* webpackPrefetch: true */ `../../../functions/get/getCongratulationHeading`)).getCongratulationHeading;
    const getParameterFromUrl = (await import(/* webpackPrefetch: true */ `../../../functions/get/getParameterFromUrl`)).getParameterFromUrl;
    const isEmpty = (await import(/* webpackPrefetch: true */ `../../universal_methods/isEmpty`)).isEmpty;



    await this.getBaseUrl();

    const loginLink = `${this.baseUrl}/login/${this.language}`;

    let heading = getParameterFromUrl({paramName:"h"});
    console.log("heading is ", heading);
    heading = !isEmpty(heading) ? heading : "txt185";

    let icon = getParameterFromUrl({paramName:"i"});
    icon = icon || "notification";

    let txt = getParameterFromUrl({paramName:"msg"});
    txt = txt || "txt766";

    let link = getParameterFromUrl({paramName:"l"});
    link = link || loginLink;

    let linkText = getParameterFromUrl({paramName:"lt"});
    linkText = linkText || "txt742";

    let color = getParameterFromUrl({paramName:"c"});
    color = color || "danger";
    

    const headingAndNotice = await getCongratulationHeading({heading, icon, txt, color});

    const content = `
      <div class="smetio-margin-top-50 smetio-text-center "> 

        ${headingAndNotice}

        <h6 class="smetio-h6-heading smetio-text-center smetio-margin-top-30">
          <a href="${link}" target="_self" class="smetio-anchor-text " >
            ${ this.setTxt({ attributes: {}, txt: linkText, type: "html"}) }
          </a>          
        </h6>

      </div>
    `;

    // console.log("Displaying good bye message...");

    this.changeRightSideUniqueContent(content);

  };

  events() {

    // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

    //     $("."+this.uploadFilesInputClass).trigger("click");

    // });	

  };

};

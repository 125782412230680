//import {test_function} from "./../functions/test_function.js";



import { SmetioSetUpAndInitialization } from "../../smetioSetUpAndInitialization/v1.0.0/SmetioSetUpAndInitialization.js";



// import {getId} from "./../../universal_methods/getId.js";  
// import {setId} from "./../../universal_methods/setId.js";
// import {generateId} from "./../../universal_methods/generateId.js";
// import {isEmpty} from "./../../universal_methods/isEmpty.js";  
// import {getAttributes} from "./../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "./../../universal_methods/toggleDisplay.js"; 
// import {universal_handleErrorFromServer} from "./../../universal_methods/universal_handleErrorFromServer.js";  
// import {combineSmetioAttributes} from "./../../universal_methods/combineSmetioAttributes.js";  
// import { setTxt } from "../../universal_methods/setTxt.js";



export class SmetioCustomerWebsiteController extends SmetioSetUpAndInitialization {

      constructor(data = {}) {

            super(data);

            this.data = data;
            this.coverImage = data.coverImage;
            // this.preloader = new SmetioPreloader();
            // this.languageOptions = new SmetioLanguageOptions(data);
            this.defaultTimeout = 200;
            this.addToSubmissionData = {};
            this.publicAccessToken = data.publicAccessToken;

            console.log("++++++++++++++++++++++++++++++ this.publicAccessToken is ", this.publicAccessToken);
            // this.usefulLinks = data.usefulLinksObj ? data.usefulLinksObj.links : [];

            // this.mainPageClass = "smetio-page-" + this.id;
            // this.mainPageAttributes = {
            //   class: ["smetio-page", this.mainPageClass],
            // };

      };

  

      // findOurLocationTextInUsefullLinks(){

      //   for (let i = 0; i < this.usefulLinks.length; i++) {

      //     const linkObj = this.usefulLinks[i];
      
      //     console.log("linkObj is ", linkObj);

      //     const {href, text} = linkObj

      //     if(href == "#smetio-template-locations") return text;

      //   };

      // };

      async displayLanguageIcon(){

            const SmetioLanguageOptions = (await import(/* webpackPrefetch: true */ "../../smetioLanguageOptions/v1.0.0/SmetioLanguageOptions.js")).SmetioLanguageOptions;

            this.languageOptions = new SmetioLanguageOptions(this.data);
            const languageIcon = this.languageOptions.render();
            $(".smetio-template-language-btn-div").html(languageIcon);

            // $(".smetio-template-1-more-content").html("");

      };

      async displayBusinessLocations(){

            const SmetioCustomerWebsiteBusinessLocations = (await import(/* webpackPrefetch: true */ "../../smetioCustomerWebsiteBusinessLocations/v1.0.0/SmetioCustomerWebsiteBusinessLocations.js")).SmetioCustomerWebsiteBusinessLocations;

            const smetioCustomerWebsiteBusinessLocations = new SmetioCustomerWebsiteBusinessLocations(this.data);
            const businessLocations = await smetioCustomerWebsiteBusinessLocations.render();

            console.log(" ******* businessLocations are ", businessLocations);

            $(".smetio-template-1-more-content").append(businessLocations);
  
      };

      async displayTeamMembers(){

            const SmetioCustomerWebsiteTeamMembers = (await import(/* webpackPrefetch: true */ "../../smetioCustomerWebsiteTeamMembers/v1.0.0/SmetioCustomerWebsiteTeamMembers.js")).SmetioCustomerWebsiteTeamMembers;

            const smetioCustomerWebsiteTeamMembers = new SmetioCustomerWebsiteTeamMembers(this.data);
            const teamMembers = await smetioCustomerWebsiteTeamMembers.render();

            $(".smetio-template-1-more-content").append(teamMembers);

      };

      getNextKey(data = {}) {

            const {currentKey, object} = data;
            // Get an array of the object's keys
            const keys = Object.keys(object);
    
            // Find the index of the current key
            const currentIndex = keys.indexOf(currentKey);
    
            // If the current key is found and it's not the last key
            if (currentIndex !== -1 && currentIndex < keys.length - 1) {
                  // Return the next key
                  return keys[currentIndex + 1];
            } else {
                  // If the current key is the last one or not found, return null or undefined
                  return null;
            }
      };
  
      async render() {
  
            this.websiteLayout = ["locations", "teams", "services"];

            const SmetioCustomerWebsiteTeamMembers = (await import(/* webpackPrefetch: true */ "../../smetioCustomerWebsiteTeamMembers/v1.0.0/SmetioCustomerWebsiteTeamMembers.js")).SmetioCustomerWebsiteTeamMembers;
            const SmetioCustomerWebsiteBusinessLocations = (await import(/* webpackPrefetch: true */ "../../smetioCustomerWebsiteBusinessLocations/v1.0.0/SmetioCustomerWebsiteBusinessLocations.js")).SmetioCustomerWebsiteBusinessLocations;

            const SmetioCustomerWebsiteTreatments = (await import(/* webpackPrefetch: true */ "../../smetioCustomerWebsiteTreatments/v1.0.0/SmetioCustomerWebsiteTreatments.js")).SmetioCustomerWebsiteTreatments;


            const smetioCustomerWebsiteTeamMembers = new SmetioCustomerWebsiteTeamMembers(this.data);
            const smetioCustomerWebsiteBusinessLocations = new SmetioCustomerWebsiteBusinessLocations(this.data);
            const smetioCustomerWebsiteTreatments = new SmetioCustomerWebsiteTreatments(this.data);

            const websiteSections = {
                  locations: smetioCustomerWebsiteBusinessLocations,
                  teams:smetioCustomerWebsiteTeamMembers,
                  services:smetioCustomerWebsiteTreatments
            };

            let output = "";

            for (let i = 0; i < this.websiteLayout.length; i++) {
                  const section = this.websiteLayout[i];
                  const sectionClass = websiteSections[section];

                  const nextSection = this.websiteLayout[i+1];

                  const nextSectionClass = websiteSections[nextSection];
                  sectionClass.nextSectionClass = nextSectionClass;

                  output += sectionClass.render({});
            };

            $(".smetio-template-1-more-content").html(output);

            const firstItemOnLayout = this.websiteLayout[0];
            websiteSections[firstItemOnLayout].display();

            this.displayLanguageIcon();
  
            this.removeSplashScreen();

      };


      events() {

            // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

            //     $("."+this.uploadFilesInputClass).trigger("click");

            // });	

      };

};

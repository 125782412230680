
// import {events} from "./methods/events.js";
// import {render} from "./methods/render.js";
import { getId } from "../universal_methods/getId.js";
import { setId } from "../universal_methods/setId.js";

import { getAttributes } from
    "../universal_methods/getAttributes.js";

// //import "./styles/_main.scss";

export class SmetioOnOffSwitch {

    constructor(data = {
        attributes: {},
        changeEvents: {
            onChecked: (value) => { },
            onUnChecked: (value) => { },
        }
    }) {


        this.getId = getId;
        this.setId = setId;
        this.getAttributes = getAttributes;

        this.isReadonly = data.attributes.isReadonly;
        this.inputIsChecked = data.attributes.checked;
        this.changeEvents = data.changeEvents;

        this.getId();


        this.mainClass = "smetio-ui-on-off-switch-" + this.id;
        this.mainAttributes = {
            /****
             *  CAN BE FILLED WITH DIFFERENT ATTRIBUTES. THESE ATTRIBUTES AND THEIR VALUES WILL 
             * BE APPLIED DIRECTLY TO THE MAIN ACCORDION DIV
             *  
             * */
            class: ["smetio-ui-on-off-switch", this.mainClass]
        };

        this.inputClass = "smetio-ui-on-off-switch-input" + this.id;
        this.inputAttributes = {
            type: "checkbox",
            checked: this.inputIsChecked === true ? true : false,
            "data-smetio-read-only": this.isReadonly,
            class: ["smetio-ui-on-off-switch-input", this.inputClass],
        };

        this.sliderClass = "smetio-switch-slider-" + this.id;
        this.sliderAttributes = {
            "data-smetio-read-only": this.isReadonly,
            class: ["smetio-switch-slider", this.sliderClass, "round"],
        };

    };
    getValue() {
        return this.inputIsChecked;
    };
    changeReadonlyStatus({ newStatus = true }) {

        this.isReadonly = newStatus;

        $("." + this.inputClass).attr("data-smetio-read-only", this.isReadonly);
        $("." + this.sliderClass).attr("data-smetio-read-only", this.isReadonly);

    };
    events() {

        $("body").on("click", "." + this.sliderClass + ", ." + this.inputClass, (event) => {
            // event.stopImmediatePropagation();
            // event.stopPropagation();
        });

        $("body").on("change", "." + this.inputClass, (event) => {

            // event.stopPropagation();
            // event.stopImmediatePropagation();
            this.inputIsChecked = $("." + this.inputClass).prop("checked");

            if (!this.inputIsChecked && this.changeEvents.onUnChecked) return this.changeEvents.onUnChecked(false);
            if (this.inputIsChecked && this.changeEvents.onChecked) return this.changeEvents.onChecked(true);

        });

    };
    render() {

        const output = `
            <div ${this.getAttributes(this.mainAttributes, false)}>
                <label class="smetio-switch">
                    <input ${this.getAttributes(this.inputAttributes, false)}>
                    <span ${this.getAttributes(this.sliderAttributes, false)}></span>
                </label>
            </div>
        `;

        this.events();

        return output;

    };
};



// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 

import { SmetioPage } from "../../smetioPage/v1.0.0/SmetioPage";


//import "./styles/_main.scss";


export class SmetioLoginPage extends SmetioPage{

  constructor(data = {}) {

    const lastUsedAppLink = localStorage.getItem("lastUsedAppLink");
    const loginToken = localStorage.getItem("loginToken");
    console.log("The loginToken ", loginToken);
    if(loginToken) {
      console.log("Redirecting to the last app that was used ",lastUsedAppLink );
      window.parent.location.href = lastUsedAppLink;
    }


    super(data);

    this.showSignUpLink = true;
    this.showResetPasswordLink = true;



    this.mainClass = "smetio-login-page" + this.id;
    this.mainAttributes = {
      class: ["smetio-login-page", this.mainClass],
    };
  };

  async getModelParameters(data = {}) {

    const apiEndPoint = window.apiEndPoint;
    const language = window.userLanguage;

    const url = `${window.defaultPath}login/model/${language}`;

    
    const {smetio_rdal} = this.extraParameters;
    this.addToSubmissionData = {smetio_rdal};

    return {
      url,
      submitText: "txt138",
      instructionTitle: "txt124",
      params:{},
      // instructionSubtitle: "txt598",
      submissionFunctionDetails:{
        // functionPath: "login_and_signup/submitLoginDetails",
        predefinedFunction:this.submitFormData,
        functionParameters: {
          preloaderClass: this.preloader,
          url:`${window.defaultPath}login/${language}`,
          modelPath: "pages/login/loginModel"
        }
      }
    };

  };

  events() {

    // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

    //     $("."+this.uploadFilesInputClass).trigger("click");

    // });	

  };

};



// import {getId} from "../../universal_methods/getId.js";  
// import {setId} from "../../universal_methods/setId.js";
// import {generateId} from "../../universal_methods/generateId.js";
// import {isEmpty} from "../../universal_methods/isEmpty.js";  
// import {getAttributes} from "../../universal_methods/getAttributes.js";  
// import {toggleDisplay} from "../../universal_methods/toggleDisplay.js"; 

import { SmetioPage } from "../../smetioPage/v1.0.0/SmetioPage";


//import "./styles/_main.scss";


export class SmetioSignupPage extends SmetioPage{

      constructor(data = {}) {

            super(data);

            this.showLoginLink = true;
            this.showResetPasswordLink = true;

            this.mainClass = "smetio-signup-page" + this.id;
            this.mainAttributes = {
                  class: ["smetio-signup-page", this.mainClass],
            };
      };

      async getModelParameters(data = {}) {

            const apiEndPoint = window.apiEndPoint;
            const language = window.userLanguage;

            const url = `${window.defaultPath}signup/model/${language}`;

            return {
                  url,
                  submitText: "txt737",
                  instructionTitle: "txt736",
                  params:{},
                  // instructionSubtitle: "txt598",
                  submissionFunctionDetails:{
                        // functionPath: "signup_and_signup/submitLoginDetails",
                        predefinedFunction:this.submitFormData,
                        functionParameters: {
                              preloaderClass: this.preloader,
                              url:`${window.apiEndPoint}/user/${language}`,
                              modelPath: "pages/signup/signupModel"
                        }
                  }
            };

      }; 

      events() {

            // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

            //     $("."+this.uploadFilesInputClass).trigger("click");

            // });	

      };

};
